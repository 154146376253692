body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-layout-header{
  background:#ffffff !important;
}
.ant-menu{
  text-align:center !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-layout-content{
  border-radius: 4px !important;
  background-color: rgb(253 253 253 / 80%) !important;
  position: relative;
}
.slick-dots{
  transition: all .4s;
}
.slick-dots:hover{
  transform: scale(2);
}
html,body,#root,.App{
  height: 100%;
}
body{
  background: #ADA996;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.ant-layout-content{
  background: rgba(255,255,255,1) !important ;
}
.ant-layout{
  background: rgba(255,255,255,1) !important ;
}
